document.addEventListener("DOMContentLoaded", function(event) {


    if (document.querySelectorAll("#btn-go-top").length) {
        {
            document.querySelector("#btn-go-top").addEventListener("click", (e) => {
                e.preventDefault();
                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                    passive: true,
                });
            });
        }
    }
    if (document.querySelectorAll(".go-bottom").length) {
        document.querySelector(".go-bottom").addEventListener("click", (e) => {
            e.preventDefault();
            let nr = window.innerHeight / 2;

            window.scrollTo({
                top: nr,
                behavior: "smooth",
                passive: true,
            });
        });
    }

    if (document.querySelectorAll("#go-to-gallery").length) {
        document.querySelector("#go-to-gallery").addEventListener("click", (e) => {
            e.preventDefault();
            let elem = document.querySelector("#h-sceny");
            let oT = elem.offsetTop - 70;
            window.scrollTo({
                top: oT,
                behavior: "smooth",
                passive: true,
            });
        });
    }
    if (document.querySelectorAll("#go-to-film").length) {
        document.querySelector("#go-to-film").addEventListener("click", (e) => {
            e.preventDefault();
            let elem = document.querySelector("#h-galeria");
            let oT = elem.offsetTop - 70;
            window.scrollTo({
                top: oT,
                behavior: "smooth",
                passive: true,
            });
        });
    }






    isMobile = false;
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
        isMobile = true;
    }

    function checkMobile() {
        if (isMobile) {
            document.querySelector("body").classList.add("is--mobile");
            document.querySelector("body").classList.remove("is--desktop");
        } else {
            document.querySelector("body").classList.remove("is--mobile");
            document.querySelector("body").classList.add("is--desktop");
        }
    }
    checkMobile();

    function isInViewport(element) {
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 && rect.left >= 0 &&
            // rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    let moveScroll = 0;

    function moveElementOnScroll() {
        const scrollOffset = 0;


        const scrollElement = document.querySelector(".js-scroll-title");

        const elementInView = (el, offset = 0) => {
            const elementTop = el.getBoundingClientRect().top;

            return (
                elementTop <=
                ((window.innerHeight || document.documentElement.clientHeight) - offset)
            );
        };

        const displayScrollElement = () => {
            if (document.querySelector("body").classList.contains("scrollToBottom")) {

                moveScroll = moveScroll + 1
            } else {

                moveScroll = moveScroll - 1;
            }
            if (moveScroll < 40 && moveScroll > 0) { scrollElement.style.left = moveScroll + "%"; }

        }

        const hideScrollElement = () => {
            scrollElement.classList.remove('scrolled');
            moveScroll = 0;
        }

        const handleScrollAnimation = () => {
            if (elementInView(scrollElement, scrollOffset)) {
                displayScrollElement();
            } else {
                hideScrollElement();
            }
        }
        handleScrollAnimation();
    }

    let lastScrollValue = 0;
    window.addEventListener("scroll", function(e) {

        if (document.querySelectorAll(".header").length) {
            var navbar = document.querySelectorAll(".header")[0];

            function addFixedMenu() {
                if (window.pageYOffset >= navbar.clientHeight + 300) {
                    navbar.classList.add("fixed-header-scroll");
                    document.querySelector("body").classList.add("menu-is-fixed");
                } else {
                    navbar.classList.remove("fixed-header-scroll");
                    document.querySelector("body").classList.remove("menu-is-fixed");
                }
            }

            if (document.body.clientHeight > (window.innerHeight + 400)) {
                addFixedMenu();
            }
        }



        if (lastScrollValue <= window.scrollY) {
            if (!document.querySelector("body").classList.contains("scrollToBottom")) {
                { document.body.classList.add("scrollToBottom"); }
            }
        } else {
            document.body.classList.remove("scrollToBottom");

        }
        lastScrollValue = window.scrollY;

        if (document.querySelector(".js-scroll-title")) {

            moveElementOnScroll()
        }
    }, { passive: true });



    let slideUp = (target, duration = 500) => {

        target.style.transitionProperty = 'height, margin, padding';
        target.style.transitionDuration = duration + 'ms';
        target.style.boxSizing = 'border-box';
        target.style.height = target.offsetHeight + 'px';
        target.offsetHeight;
        target.style.overflow = 'hidden';
        target.style.height = 0;
        target.style.paddingTop = 0;
        target.style.paddingBottom = 0;
        target.style.marginTop = 0;
        target.style.marginBottom = 0;
        window.setTimeout(() => {
            target.style.display = 'none';
            target.style.removeProperty('height');
            target.style.removeProperty('padding-top');
            target.style.removeProperty('padding-bottom');
            target.style.removeProperty('margin-top');
            target.style.removeProperty('margin-bottom');
            target.style.removeProperty('overflow');
            target.style.removeProperty('transition-duration');
            target.style.removeProperty('transition-property');
            //alert("!");
        }, duration);
    }

    /* SLIDE DOWN */
    let slideDown = (target, duration = 500) => {

        target.style.removeProperty('display');
        let display = window.getComputedStyle(target).display;
        if (display === 'none') display = 'block';
        target.style.display = display;
        let height = target.offsetHeight;
        target.style.overflow = 'hidden';
        target.style.height = 0;
        target.style.paddingTop = 0;
        target.style.paddingBottom = 0;
        target.style.marginTop = 0;
        target.style.marginBottom = 0;
        target.offsetHeight;
        target.style.boxSizing = 'border-box';
        target.style.transitionProperty = "height, margin, padding";
        target.style.transitionDuration = duration + 'ms';
        target.style.height = height + 'px';
        target.style.removeProperty('padding-top');
        target.style.removeProperty('padding-bottom');
        target.style.removeProperty('margin-top');
        target.style.removeProperty('margin-bottom');
        window.setTimeout(() => {
            target.style.removeProperty('height');
            target.style.removeProperty('overflow');
            target.style.removeProperty('transition-duration');
            target.style.removeProperty('transition-property');
        }, duration);
    }

    /* TOOGLE */
    var slideToggle = (target, duration = 500) => {
        if (window.getComputedStyle(target).display === 'none') {
            return slideDown(target, duration);
        } else {
            return slideUp(target, duration);
        }
    }


    document.querySelector(".nav-toggle").addEventListener("click", function(e) {
        e.preventDefault();
        e.stopPropagation();
        this.classList.toggle("open");
        if (this.classList.contains("open")) {
            document.querySelector("body").classList.add("menu-open");
            slideDown(document.querySelector(".nav"), 300);
        } else {
            if (document.querySelector(".nav ul.open")) {
                slideUp(document.querySelector(".nav ul.open"), 300);
            }
            slideUp(document.querySelector(".nav"), 300);
            document.querySelector("body").classList.remove("menu-open");
        }
        e.preventDefault();
        e.stopPropagation();
    })

    const navItemToOpenA = document.querySelectorAll('.menu__item--has-children>a');
    const navItemToOpenLi = document.querySelectorAll('.menu__item--has-children');


    // previousElementSibling
    if (window.ontouchstart !== undefined) {
        document.querySelector("body").classList.add("menu-mobile");

        for (var i = 0; i < navItemToOpenA.length; i++) {
            navItemToOpenA[i].addEventListener("click", function(e) {
                e.preventDefault();
                e.stopPropagation();
                let submenu = this.nextElementSibling;

                if (submenu.classList.contains("open")) {
                    slideUp(submenu, 300);
                    submenu.classList.remove("open");
                    document.querySelector("body").classList.remove("menu-is-open");
                } else {
                    if (document.querySelector(".nav .nav__level-2.open")) {
                        slideUp(document.querySelector(".nav .nav__level-2.open"), 300);
                        document.querySelector(".nav .nav__level-2.open").classList.remove("open");
                    }
                    slideDown(submenu, 300);
                    submenu.classList.add("open");
                    document.querySelector("body").classList.add("menu-is-open");
                }
            });
        }


        // $(".team__item").click(function(e) {
        //     if ($(this).hasClass("open")) {
        //         $(this).removeClass("open");
        //     } else {
        //         $(".team__item.open").removeClass("open");
        //         $(this).addClass("open");
        //     }
        // })

    } else {
        for (var i = 0; i < navItemToOpenA.length; i++) {
            navItemToOpenA[i].addEventListener("click", function(e) {
                // e.preventDefault();
                // e.stopPropagation();
            });
        }
        for (var i = 0; i < navItemToOpenLi.length; i++) {
            navItemToOpenLi[i].addEventListener("mouseenter", function(e) {
                this.querySelector('.nav__level-2').style.display = "block";
                document.querySelector("body").classList.add("menu-is-open");
            });
        }
        for (var i = 0; i < navItemToOpenLi.length; i++) {
            navItemToOpenLi[i].addEventListener("mouseleave", function(e) {
                this.querySelector('.nav__level-2').style.display = "none";
                document.querySelector("body").classList.remove("menu-is-open");
            });
        }
        // for (var i = 0; i < teamItemList.length; i++) {
        //     teamItemList[i].addEventListener("mouseenter", function(e) {
        //         this.classList.add("open");
        //     });
        // }
        // for (var i = 0; i < teamItemList.length; i++) {
        //     teamItemList[i].addEventListener("mouseout", function(e) {
        //         this.classList.remove("open");
        //     });
        // }       
    }



    // document.addEventListener('click', function(e) {

    //     if (!e.target.parentNode.classList.contains("team__item-right") && !e.target.classList.contains("team__item-right") && !e.target.parentNode.classList.contains("team__noclick")) {
    //         if (document.querySelector(".team__item.open")) {
    //             document.querySelector(".team__item.open").classList.remove("open");
    //         }

    //     }
    // });


    const teamItemList = document.querySelectorAll('.team__item-left');
    const teamClose = document.querySelectorAll('.team__close');
    for (let i = 0; i < teamItemList.length; i++) {
        teamItemList[i].addEventListener("click", function(e) {
            let teamItem = this.parentNode;
            if (teamItem.classList.contains("open")) {
                teamItem.classList.remove("open")
            } else {
                if (document.querySelector(".team__item.open")) {
                    document.querySelector(".team__item.open").classList.remove("open")
                }
                teamItem.classList.add("open")
            }
        });
    }

    function accordion(accordion) {
        let accordionContent = accordion.querySelector(".accordion__content");
        let accordionBtn = accordion.querySelector("button");
        let height = accordionContent.offsetHeight;

        accordionContent.setAttribute("data-height", height + "px");
        accordionContent.classList.contains("active") ? accordionContent.style.height = height + "px" : accordionContent.style.height = 0;
        accordionBtn.addEventListener("click", function() {
            if (accordion.classList.contains("active")) {
                this.classList.remove("active");
                this.setAttribute('aria-expanded', false);
                accordionContent.classList.remove("active");
                accordionContent.setAttribute('aria-hidden', true);
                accordionContent.style.height = 0;
                accordion.classList.remove("active");
            } else {
                this.classList.add("active");
                this.setAttribute('aria-expanded', true);
                accordion.classList.add("active");
                accordionContent.setAttribute('aria-hidden', false);
                accordionContent.classList.add("active");
                accordionContent.style.height = accordion.querySelector(".accordion__content").getAttribute("data-height");
            }
        })
    }

    Array.prototype.map.call(document.querySelectorAll(".accordion__panel"), function(item) { accordion(item) });


    if (document.querySelector(".offert__wrapper")) {
        document.querySelector(".offert__wrapper").style.minHeight = document.querySelector(".offert__wrapper").clientHeight + "px";
        const offerBtn = document.querySelectorAll(".offert__nav button");
        const offerItem = document.querySelectorAll(".offert__item")
        for (let i = 0; i < offerBtn.length; i++) {
            offerBtn[i].addEventListener("click", function(e) {
                e.preventDefault();

                let offerKind = this.getAttribute("data-name");
                if (this.classList.contains("active")) {
                    if (document.querySelector(".offert__nav button.active")) { document.querySelector(".offert__nav button.active").classList.remove("active"); }
                    for (let i = 0; i < offerItem.length; i++) {
                        offerItem[i].classList.remove("offer-hide");
                        offerItem[i].classList.add("offer-show");
                    }
                    // setTimeout(function() {
                    //     for (let i = 0; i < offerItem.length; i++) {
                    //         offerItem[i].classList.remove("offer-show");
                    //     }
                    // }, 300)
                } else {
                    if (document.querySelector(".offert__nav button.active")) { document.querySelector(".offert__nav button.active").classList.remove("active"); }
                    this.classList.add("active");

                    for (let i = 0; i < offerItem.length; i++) {


                        if (offerItem[i].getAttribute("data-name") == offerKind) {
                            // offerItem[i].classList.add("offer-show");
                            offerItem[i].classList.remove("offer-hide");
                        } else {
                            offerItem[i].classList.add("offer-hide");
                            // offerItem[i].classList.remove("offer-show");
                        }

                    }
                }

            });
        }
    }



    //GESTURE




    //swiper slder

    //TAB
    var tabs = document.querySelectorAll('[role=tab]'); //get all role=tab elements as a variable
    for (i = 0; i < tabs.length; i++) {
        tabs[i].addEventListener("click", showTabPanel);
    } //add click event to each tab to run the showTabPanel function 
    function showTabPanel(el) { //runs when tab is clicked
        var tabs2 = document.querySelectorAll('[role=tab]'); //get tabs again as a different variable 
        for (i = 0; i < tabs2.length; i++) {
            tabs2[i].setAttribute('aria-selected', 'false');

        } //reset all tabs to aria-selected=false and normal font weight
        el.target.setAttribute('aria-selected', 'true'); //set aria-selected=true for clicked tab       
        var tabPanelToOpen = el.target.getAttribute('aria-controls'); //get the aria-controls value of the tab that was clicked
        var tabPanels = document.querySelectorAll('[role=tabpanel]'); //get all tabpanels as a variable
        for (i = 0; i < tabPanels.length; i++) {
            tabPanels[i].setAttribute("aria-hidden", "true");
            tabPanels[i].classList.remove("is-current");

        } //hide all tabpanels
        document.getElementById(tabPanelToOpen).setAttribute("aria-hidden", "false");
        document.getElementById(tabPanelToOpen).classList.add("is-current"); //show tabpanel who's tab was clicked
    }

    let touchstartX = 0;
    let touchendX = 0;


    if (document.querySelector('.tabs__panels')) {

        const gestureZone = document.querySelector('.tabs__panels');


        gestureZone.addEventListener('touchstart', function(event) {
            touchstartX = event.changedTouches[0].screenX;
        }, { passive: true });

        gestureZone.addEventListener('touchend', function(event) {
            touchendX = event.changedTouches[0].screenX;
            handleGesture();
        }, { passive: true });
    }

    function handleGesture() {
        if (touchendX >= touchstartX) {
            if (document.querySelector(".tab__wrapper [aria-selected=true]").parentNode.previousElementSibling) {
                document.querySelector(".tab__wrapper [aria-selected=true]").parentNode.previousElementSibling.querySelector("button").click();
            }
        }
        if (touchendX <= touchstartX) {
            if (document.querySelector(".tab__wrapper [aria-selected=true]").parentNode.nextElementSibling) {
                document.querySelector(".tab__wrapper [aria-selected=true]").parentNode.nextElementSibling.querySelector("button").click();
            }
        }

    };

    require('fslightbox');




    let observeOptions = {
        root: null,
        margin: '0px',
        threshold: 0.4,
    }
    let observeOptionsFast = {
        root: null,
        margin: '0px',
        threshold: 0.1,
    }

    let onObserve = function(entries, observer) {
        let el = entries[0].target;

        let elChildren = el.querySelectorAll(".offert__item");

        for (i = 0; i < entries.length; ++i) {

            if (entries[i].isIntersecting) {
                if (!el.classList.contains("io-loaded")) {
                    for (i = 0; i < elChildren.length; ++i) {
                        elChildren[i].classList.add('offer-show');
                    }
                    el.classList.add('io-loaded');
                    setTimeout(function() {
                        el.classList.add('io-loaded-all');
                    }, 800);

                }
            }
        }

    }


    if (document.querySelector('.offert__wrapper')) {
        const iObserver = new IntersectionObserver(onObserve, observeOptions);
        if (document.querySelector(".offert__wrapper")) { iObserver.observe(document.querySelector(".offert__wrapper")); }
    }



    let onObserve2 = function(entries, observer) {
        for (i = 0; i < entries.length; ++i) {
            let el = entries[i].target;

            if (entries[i].isIntersecting) {
                if (!el.classList.contains("wow-loaded")) {
                    el.classList.add('wow-loaded');
                }
            }
        }
    }

    if (document.querySelector('.wow-team')) {
        const iObserver3 = new IntersectionObserver(onObserve2, observeOptionsFast);
        iObserver3.observe(document.querySelector(".wow-team"));
    }

    if (document.querySelector('.wow')) {
        let wowItem = document.querySelectorAll(".wow");
        for (i = 0; i < wowItem.length; ++i) {
            const iObserver2 = new IntersectionObserver(onObserve2, observeOptions);
            iObserver2.observe(wowItem[i]);
        }
    }

    if (document.querySelector('.link__parent')) {
        let linkParents = document.querySelectorAll(".link__parent");
        for (i = 0; i < linkParents.length; ++i) {
            linkParents[i].addEventListener("click", function() {
                let href = linkParents[i].querySelector("a").getAttribute("href");
                if (href) { window.location.href = href; }
            });
        }
    }



    if (document.querySelector('.films__optionsBtn')) {
        let filmsOptionsBtn = document.querySelector('.films__optionsBtn');
        let filmsOptionsSelects = document.querySelector('.films__optionsSelects');

        filmsOptionsBtn.addEventListener('click', function(e) {
            e.preventDefault();
            slideToggle(filmsOptionsSelects, 300);
        })

    }


    //nav podstrona
    let btnNavA = document.querySelector("#article4__navBtn");
    let ulNavA = document.querySelector("#article4__navUl");

    if (btnNavA) {
        btnNavA.addEventListener("click", function() {
            if (btnNavA.classList.contains("open")) {
                this.classList.remove("open");
                slideUp(ulNavA, 300);
            } else {
                this.classList.add("open");
                slideDown(ulNavA, 300);
            }
        })
    }



});


if (document.querySelector('.onclick-select')) {
    let onclickSelect = document.querySelectorAll(".onclick-select");
    for (i = 0; i < onclickSelect.length; ++i) {
        onclickSelect[i].addEventListener('click', function(e) {
            this.select();
        });
    }
}

if (document.querySelector('.item-change')) {
    let itemChange = document.querySelectorAll(".item-change");
    for (i = 0; i < itemChange.length; ++i) {
        itemChange[i].addEventListener('change', function(e) {
            e.target.form.submit();
        })
    }
}


let onObservePageBtn = function(entries, observer) {
    for (i = 0; i < entries.length; ++i) {
        let el = entries[i].target;

        if (entries[i].isIntersecting) {
            let id = el.getAttribute("id");

            if (document.querySelector("[data-href=" + id + "]")) {
                document.querySelector(".page__nav .active").classList.remove("active");
                document.querySelector("[data-href=" + id + "]").classList.add("active");
            }
            // { document.querySelector("article.is-view").classList.remove("is-view"); }
            // el.document.querySelector("[data-href="+id+"]");

        }
    }
}
if (document.querySelector('.page__nav button')) {
    let observeOptionsFast2 = {
        root: null,
        rootMargin: '-50% 0% -50% 0%',
        threshold: 0,
    }
    let pageNavBtns = document.querySelectorAll(".page__nav button");
    for (i = 0; i < pageNavBtns.length; ++i) {
        pageNavBtns[i].addEventListener('click', function(e) {
            e.preventDefault();
            let href = "#" + e.target.getAttribute('data-href');
            let elem = document.querySelector(href);
            let oT = elem.offsetTop - 70;
            if (href == "#h-informacje") {
                oT = oT - 30;
            }
            if (href == "#h-kontakt") {
                oT = oT - 30;
            }
            window.scroll({
                top: oT,
                behavior: 'smooth',
                passive: true,
            });
        })
    }

    let articleItem = document.querySelectorAll(".article__wrapper article");
    for (i = 0; i < articleItem.length; ++i) {
        const iObserverNavBtns = new IntersectionObserver(onObservePageBtn, observeOptionsFast2);
        iObserverNavBtns.observe(articleItem[i]);
    }

}

// if (document.querySelectorAll(".article__item, .newsM__item")) {
//     let newsItems = document.querySelectorAll(".article__item, .newsM__item");
//     for (i = 0; i < newsItems.length; ++i) {
//         let href = newsItems[i].querySelector("h3 a").getAttribute("href");
//         newsItems[i].querySelector("img").addEventListener('click', function(e) {
//             if (href) {
//                 window.location.href = href;
//             }
//         })
//     }
// }


if (document.querySelector('.bigP__wrapperSlider')) {
    let bigPItems = document.querySelectorAll(".bigP__wrapperSlider img");
    let bigPItemsT = document.querySelectorAll(".bigP__title");
    let bigPItemsLength = bigPItems.length;
    let bigPItemActive = 0;


    if (bigPItems.length > 1) {
        setInterval(function() {
            bigPItems[bigPItemActive].classList.remove("active");
            bigPItemsT[bigPItemActive].classList.remove("active");
            if (bigPItemActive == bigPItemsLength - 1) {
                bigPItemActive = 0;
            } else {
                bigPItemActive++;
            }
            bigPItems[bigPItemActive].classList.add("active");
            bigPItemsT[bigPItemActive].classList.add("active");

        }, 10000)
    }
}


if (document.querySelector('#film__btnMore')) {
    let hideLi = document.querySelectorAll(".film__list .js-novisible");
    let filmBtnMore = document.querySelector('#film__btnMore');
    let filmBtnMoreTxt1 = document.querySelector('#film__btnMore .show');
    let filmBtnMoreTxt2 = document.querySelector('#film__btnMore .hide');
    filmBtnMore.addEventListener('click', function(e) {
        for (i = 0; i < hideLi.length; ++i) {
            // hideLi[i].classList.toggle("js-novisible");
            hideLi[i].classList.toggle("js-visible");
        }
        filmBtnMoreTxt1.classList.add("hide");
        filmBtnMoreTxt1.classList.remove("show");
        filmBtnMoreTxt2.classList.add("show");
        filmBtnMoreTxt2.classList.remove("hide");
    })
}


/* This script supports IE9+ */
(function() {
    /* Opening modal window function */
    function openModal() {
        /* Get trigger element */
        var modalTrigger = document.getElementsByClassName('jsModalTrigger');

        /* Set onclick event handler for all trigger elements */
        for (var i = 0; i < modalTrigger.length; i++) {
            modalTrigger[i].onclick = function(e) {
                e.preventDefault();
                var target = this.getAttribute('data-href');
                var modalWindow = document.getElementById(target);

                modalWindow.classList ? modalWindow.classList.add('open') : modalWindow.className += ' ' + 'open';
            }
        }
    }

    function closeModal() {
        /* Get close button */
        var closeButton = document.getElementsByClassName('jsModalClose');
        var closeOverlay = document.getElementsByClassName('jsOverlay');

        /* Set onclick event handler for close buttons */
        for (var i = 0; i < closeButton.length; i++) {
            closeButton[i].onclick = function() {
                var modalWindow = this.parentNode.parentNode;

                modalWindow.classList ? modalWindow.classList.remove('open') : modalWindow.className = modalWindow.className.replace(new RegExp('(^|\\b)' + 'open'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
            }
        }

        /* Set onclick event handler for modal overlay */
        for (var i = 0; i < closeOverlay.length; i++) {
            closeOverlay[i].onclick = function() {
                var modalWindow = this.parentNode;

                modalWindow.classList ? modalWindow.classList.remove('open') : modalWindow.className = modalWindow.className.replace(new RegExp('(^|\\b)' + 'open'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
            }
        }

    }

    /* Handling domready event IE9+ */
    function ready(fn) {
        if (document.readyState != 'loading') {
            fn();
        } else {
            document.addEventListener('DOMContentLoaded', fn);
        }
    }

    /* Triggering modal window function after dom ready */
    ready(openModal);
    ready(closeModal);
}());



document.getElementById("newsletterBtn").addEventListener("click", function() {
    document.getElementById("newsletterEmailModal").value = document.getElementById("newsletterEmail").value;

})



if (document.querySelector('.page__wyswig img')) {
    let imgWyswig = document.querySelectorAll(".page__wyswig img");
    for (i = 0; i < imgWyswig.length; ++i) {
        let alt = imgWyswig[i].getAttribute("alt");
        if (alt.length > 0) {
            const parent = document.createElement("div");
            parent.classList.add("img__wyswig");
            const title = document.createElement("span");
            title.innerHTML = alt;
            const img = imgWyswig[i];
            let clone = img.cloneNode(true);

            parent.appendChild(clone);
            parent.appendChild(title);
            
            img.after(parent);

            img.parentNode.replaceChild(parent, img)

        }
    }

};




function ActiveLink(wrapper) {
    let links = wrapper.querySelectorAll("a");   
   
    for (let i = 0; i < links.length; i++) {

        links[i].addEventListener("click", function(e) {
            let activeLink = wrapper.querySelector("a.active");
            if (this != activeLink) {
                activeLink.classList.remove("active");
                this.classList.add("active");
            }
        })
    }
}



Array.prototype.map.call(document.querySelectorAll(".article4__nav"), function(item) { ActiveLink(item) });




if (document.querySelector("#otherAddress")) {
    document.querySelector("#otherAddress").addEventListener("change", function() {
        let input = document.querySelectorAll("#otherAddress__box input");
        if ($(this).prop("checked")) {
            slideDown(document.querySelector("#otherAddress__box"), 300);
            input.forEach(attr => {
                attr.setAttribute("required", "required");
            })
          

        } else {

            input.forEach(attr => {
                attr.removeAttribute("required");
                if (attr.closest(".is--invalid")) {
                    attr.closest(".is--invalid").classList.remove("is--invalid");
                    attr.removeAttribute("aria-describedby")

                    attr.closest(".form__input").querySelector(".form__input--error").remove();
                }

            })

            slideUp(document.querySelector("#otherAddress__box"), 300);
        }
    })
}
